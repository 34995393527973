<template>
    <div>
        <div class="general-info">
            <div class="general-info-t">
                <div class="general-info-avatar">
                    <img :src="userInfo.headPortraitUrl?userInfo.headPortraitUrl:'../../static/images/personal/default.png'" alt="">
                    <el-upload
                    :action="upload_url"
                    :multiple="false"
                    :headers="{token:token,tenantId:$store.state.tenantId}"
                    :data="{folder:'user/image'}"
                    :show-file-list="false"
                    :on-success="onsuccess"
                    :on-error="$on_error"
                    :before-upload="$before"
                    :limit="999">
                        <div class="avatar-hover">
                            <i class="iconfont icon-xiangji"></i>
                        </div>
                    </el-upload>
                    
                </div>
                <div class="general-info-t-info">
                    <div class="profile-name-info">
                        {{userInfo.nickname || '游客'}}
                    </div>
                    <div class="lvcode mt5" v-if="userInfo.recordName">
                        ID号: {{userInfo.recordName || 0}}
                    </div>
                </div>
            </div>
            <div class="general-info-b">
                <div class="c-coin">
                    <i class="iconfont icon-shoukuan"></i>
                    <span class="c-coin-info">我的余额</span>
                    <span class="c-coin-num">￥{{userInfo.avaBalance || 0}}</span>
                    <div class="cup rechargeBtn">去充值</div>
                </div>
                <span class="general-info-b-split"></span>
                <div class="grey-btn">
                    个人主页
                    <i class="iconfont icon-jinrujiantou"></i>
                </div>
            </div>
        </div>
        <div class="base-info">
            <div class="base-info-head">
                基本信息 &nbsp;&nbsp;&nbsp;
                <el-button size="mini" type="primary" v-if="!edit_" @click="edit_=true">编辑</el-button>
                <el-button size="mini" type="primary" v-if="edit_" @click="save('wechatNo')">保存</el-button>
                <el-button size="mini" v-if="edit_" @click="edit_=false">取消</el-button>
            </div>
            <div class="base-info-content">
                <div class="base-info-content-show">
                    <ul>
                        <li class="">
                            <div class="content-show-l">用户昵称</div>
                            <div class="flex" v-if="!edit_">
                                <div class="content-show-r">{{userInfo.nickname || ''}}</div>
                            </div>
                            <div class="flex" v-else>
                                <div class="edit-nickname">
                                    <el-input v-model="nickname" placeholder="请输入昵称" style="width:360px"></el-input>
                                </div>
                            </div>
                        </li>
                        <li class="">
                            <div class="content-show-l">微信号</div>
                            <div class="flex" v-if="!edit_">
                                <div class="content-show-r">{{userInfo.wechatNo || '未填写'}}</div>
                            </div>
                            <div class="flex" v-else>
                                <div class="edit-nickname">
                                    <el-input v-model="wechatNo" placeholder="请输入微信号" style="width:360px"></el-input>
                                </div>
                            </div>
                        </li>
                        <li class="">
                            <div class="content-show-l">性别</div>
                            <div class="flex" v-if="!edit_">
                                <div class="content-show-r">{{userInfo.sex=='1'?'男':'女'}}</div>
                            </div>
                            <div class="flex" v-else>
                                <div class="edit-nickname">
                                    <el-radio v-model="sex" label="1" class="mr15">男</el-radio>
                                    <el-radio v-model="sex" label="2" class="mr15">女</el-radio>
                                </div>
                            </div>
                        </li>
                        <li class="">
                            <div class="content-show-l">身高</div>
                            <div class="flex" v-if="!edit_">
                                <div class="content-show-r">{{userInfo.height || '未填写'}}</div>
                            </div>
                            <div class="flex" v-else>
                                <div class="edit-nickname">
                                    <el-select v-model="height" placeholder="请选择">
                                        <el-option
                                        v-for="item in array"
                                        :key="item"
                                        :label="item"
                                        :value="item">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                        </li>
                        <li class="">
                            <div class="content-show-l">出生日期</div>
                            <div class="flex" v-if="!edit_">
                                <div class="content-show-r">{{userInfo.birthday || '未填写'}}</div>
                            </div>
                            <div class="flex" v-else>
                                <div class="edit-nickname">
                                    <el-date-picker
                                        v-model="birthday"
                                        value-format="yyyy-MM-dd"
                                        type="date"
                                        placeholder="选择日期">
                                    </el-date-picker>
                                </div>
                            </div>
                        </li>
                        <li class="">
                            <div class="content-show-l">个性签名</div>
                            <div class="flex" v-if="!edit_">
                                <div class="content-show-r">{{userInfo.signature || '未填写'}}</div>
                            </div>
                            <div class="flex" v-else>
                                <div class="edit-nickname">
                                    <el-input v-model="signature" placeholder="请输入个性签名" style="width:360px"></el-input>
                                </div>
                            </div>
                        </li>
                        <li class="">
                            <div class="content-show-l">月收入</div>
                            <div class="flex" v-if="!edit_">
                                <div class="content-show-r">{{userInfo.monthIncome || '未填写'}}</div>
                            </div>
                            <div class="flex" v-else>
                                <div class="edit-nickname">
                                    <el-select v-model="monthIncome" placeholder="请选择月收入">
                                        <el-option
                                        v-for="item in MonthIncomeArr"
                                        :key="item.label"
                                        :label="item.label"
                                        :value="item.label">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                        </li>
                        <li class="">
                            <div class="content-show-l">城市</div>
                            <div class="flex" v-if="!edit_">
                                <div class="content-show-r">{{userInfo.cityName || '未填写'}}</div>
                            </div>
                            <div class="flex" v-else>
                                <div class="edit-nickname">
                                    <el-cascader 
                                        :props="props"
                                        :options="area_list" 
                                        v-model="citys"
                                        placeholder="请选择省市区" 
                                        ref="area"
                                        @change="area_change"
                                    >
                                    </el-cascader>
                                </div>
                            </div>
                        </li>
                        <li class="">
                            <div class="content-show-l">行业</div>
                            <div class="flex" v-if="!edit_">
                                <div class="content-show-r">{{userInfo.trade || '未填写'}}</div>
                            </div>
                            <div class="flex" v-else>
                                <div class="edit-nickname">
                                    <el-cascader 
                                        @change="change"
                                        :options="industry_list" 
                                        v-model="trades"
                                        placeholder="请选择行业" 
                                        :props="{label:'name',value:'name',children:'child'}">
                                    </el-cascader>
                                </div>
                            </div>
                        </li>
                        <li class="">
                            <div class="content-show-l">毕业院校</div>
                            <div class="flex" v-if="!edit_">
                                <div class="content-show-r">{{userInfo.school || '未填写'}}</div>
                            </div>
                            <div class="flex" v-else>
                                <div class="edit-nickname">
                                    <el-input v-model="school" placeholder="请输入毕业院校" style="width:360px"></el-input>
                                </div>
                            </div>
                        </li>
                        <li class="">
                            <div class="content-show-l">学历</div>
                            <div class="flex" v-if="!edit_">
                                <div class="content-show-r">{{education_text || '未填写'}}</div>
                            </div>
                            <div class="flex" v-else>
                                <div class="edit-nickname">
                                    <el-select v-model="education" placeholder="请选择学历">
                                        <el-option
                                        v-for="item in educationArr"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <gap height="50"></gap>
            <Homefooter ></Homefooter>
    </div>
</template>

<script>
    import { sourc, sourc2,upload_url,changePhoto2,updateMemberBase,loadList,load_area} from "@/apiUrl/index.js";
    import { mapGetters } from "vuex";
    export default {
        computed: {
            ...mapGetters(["userInfo", "config","login_show","token","MonthIncomeArr","educationArr"]),
        },
        data() {
            return {
                sourc, sourc2,upload_url,
                nickname:'', // 昵称
                wechatNo:'', // 微信号
                sex:1, // 性别
                array:[], // 身高数组
                height:'', // 身高
                birthday:'', // 出身日期
                signature:'', // 个性签名
                monthIncome:'', // 月收入
                city:'',//区级id
                citys:'',//回选
                cityName:'',//省市区
                trade:'',//行业
                trades:[],
                school:'',//毕业学校
                education_text:'',//学历
                education:'',//学历
                edit_:false,
                industry_list:[],
                industry:'',
                area_list:[],
                props: {
                    lazy: true,
                    label:'areaname',
                    value:'id',
                    lazyLoad (node, resolve) {
                        var { level } = node;
                        if(level>0){
                            load_area({pid:node.value}).then((res)=>{
                                if(res.code==200){
                                    var nodes = res.data;
                                    if(level==2){
                                        nodes.forEach((v,i)=>{
                                            v.leaf = level>=2
                                        })
                                    }
                                    resolve(nodes);
                                }else{
                                    this.$msg(res.message,3)
                                }
                            })
                        }
                    }
                }
            }
        },
        created() {
            this.getindustry();
            this.$store.dispatch("getUserInfo",(e)=>{
                setTimeout(()=>{
                    this.getdata();
                },0)
            });
            this.get_area();
            for (var i = 100; i < 230; i++) { 
			    this.array.push(i)
			}
        },
        methods:{
            edit_info(item) {
                this[item] = true;
            },
            getdata(){
                this.nickname = this.userInfo.nickname;
                this.wechatNo = this.userInfo.wechatNo;
                this.sex = this.userInfo.sex;
                this.height = this.userInfo.height;
                this.birthday = this.userInfo.birthday;
                this.signature = this.userInfo.signature;
                this.monthIncome = this.userInfo.monthIncome;
                this.city = this.userInfo.city;
                this.cityName = this.userInfo.cityName;
                this.citys = ['4522003','4522004','4522006'];
                this.trade = this.userInfo.trade;
                this.trades = this.userInfo.trade?this.userInfo.trade.split('>'):[];
                this.school = this.userInfo.school;
                this.education = this.userInfo.education;
                this.educationArr.forEach((v,i)=>{
                    if(this.userInfo.education==v.value){
                        this.education_text = v.label;
                    }
                })
            },
            save(){
                updateMemberBase({
                    nickname:this.nickname,
                    wechatNo:this.wechatNo,
                    sex:this.sex,
                    height:this.height,
                    birthday:this.birthday,
                    signature:this.signature,
                    monthIncome:this.monthIncome,
                    city:this.city,
                    trade:this.trade,

                    school:this.school,
                    education:this.education,
                }).then((res)=>{
                    if(res.code==200){
                        this.$store.dispatch("getUserInfo",(e)=>{
                            setTimeout(()=>{
                                this.getdata();
                                this.edit_ = false;
                                this.$msg('保存成功!')
                            },0)
                        });
                    }else{
                        this.$msg(res.message,3)
                    }
                })
            },
            get_area(){
                load_area({pid:''}).then((res)=>{
                    if(res.code==200){
                        this.area_list = res.data;
                    }else{
                        this.$msg(res.message,3)
                    }
                })
            },
            getindustry(){
                loadList({pid:''}).then((res)=>{
                    if(res.code==200){
                        this.industry_list = res.data || [];
                    }else{
                        this.$msg(res.message,3)
                    }
                })
            },
            onsuccess(res){
                if(res.code==200){
                    changePhoto2({
                        mid:this.userInfo.id,
                        memberType:1,
                        url:res.data.url0,
                        form:'form'
                    }).then((e)=>{
                        if(e.code==200){
                            this.$store.dispatch("getUserInfo");
                            this.$msg('修改成功!');
                        }else{
                            this.$msg(e.message,3);
                        }
                    })
                }else{
                    this.$msg(res.message,3);
                }
            },
            change(e){
                var str = ''
                if(e.length){
                    e.forEach((v,i)=>{
                        if(i>=1){
                            str+='>'+v
                        }else{
                            str = v;
                        }
                    })
                }
                this.trade = str;
            },
            area_change(e){
                this.city = this.$refs['area'].getCheckedNodes()[0].value;
            }
        }   
    }
</script>

<style lang="less" scoped>
.flex {
    display: flex;
    align-items: center;
}
.general-info {
    margin-bottom: 10px;
    background: #fff;
}
.general-info-t {
    padding: 24px 0 40px 24px;
    position: relative;
    display: flex;
    box-shadow: 0 1px 8px 0 rgb(0 0 0 / 4%);
    align-items: center;
}
.general-info-avatar {
    flex-shrink: 0;
    position: relative;
    width: 96px;
    height: 96px;
    margin-right: 16px;
    box-shadow: 0 0 10px 2px rgb(0 0 0 / 6%);
    border: 2px solid #fff;
    border-radius: 50%;
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 50%;
    }
    .avatar-hover {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        background: rgba(0,0,0,.5);
        border-radius: 50%;
        color: #fff;
        i {
            font-size: 22px;
        }
    }
}
.general-info-avatar:hover .avatar-hover {
    display: flex !important;
}
.profile-name-info {
    color: #222226;
    font-weight: 500;
    line-height: 24px;
    font-size: 20px;
    margin-right: 16px;
}
.general-info-b {
    height: 48px;
    background: #fdfdfd;
    padding-left: 25px;
    font-size: 14px;
    display: flex;
    align-items: center;
}
.c-coin {
    display: flex;
    align-items: center;
    .iconfont {
        color: #fc5531;
        font-size: 18px;
    }
    .c-coin-info {
        color: #fc5531;
        margin-right: 8px;
    }
    .c-coin-num {
        color: #222226;
        font-weight: 500;
        margin-right: 85px;
    }
    .rechargeBtn {
        color: #fff;
        background: #fc5531;
        display: inline-block;
        width: 72px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        border-radius: 15px;
        font-size: 14px;
    }
}
.general-info-b-split {
    width: 1px;
    height: 16px;
    background: #ccccd8;
    margin: 0 32px;
}
.grey-btn {
    display: flex;
    align-items: center;
    background: #f2f2f2;
    color: #555666;
    height: 28px;
    border-radius: 16px;
    padding: 0 12px;
    cursor: pointer;
}
.base-info {
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
}
.base-info-head {
    height: 48px;
    line-height: 48px;
    font-size: 18px;
    font-weight: 600;
    color: #2e2e2e;
    padding-left: 16px;
    border-bottom: 1px solid #f0f0f2;
}
.base-info-content {
    padding: 16px;
}
.base-info-content-show {
    padding: 16px 58px 16px 0;
    position: relative;
    ul {
        li {
            line-height: 27px;
            font-size: 14px;
            margin-bottom: 32px;
            display: flex;
            align-items: baseline;
            cursor: pointer;
            .content-show-l {
                width: 56px;
                color: #555666;
                margin-right: 40px;
                flex-shrink: 0;
                padding-left: 16px;
                display: flex;
                box-sizing: content-box;
                justify-content: space-between;
            }
            .content-show-r {
                color: #222226;
                word-break: break-all;
            }
            .edit-icon {
                display: none;
                margin-left: 24px;
                cursor: pointer;
                color: #1989fa;
                font-size: 14px;
                min-width: 50px;
            }
        }
    }
}
.base-info-content-show ul li:hover .edit-icon {
    display: block !important;
}
.edit-btn {
    margin-left: 24px;
}
</style>